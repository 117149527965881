body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Mono";
    src: url("assets/Typsnitt/Helvetica\ Neue\ Regular.otf")
}

html * {
    /* font-family: "Mono" !important; */
    font-family: Arial, Helvetica, sans-serif !important
}

@media print {
    body {
        -webkit-print-color-adjust: exact;

        .no-print {
            display: none
        }
    ;
    }

;

}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the container takes at least the full viewport height */
}

header {
    flex: 0 0 auto; /* Prevents the header from growing or shrinking */
}

main {
    flex: 1 0 auto; /* Allows the main section to grow and fill the remaining space */
}

footer {
    flex: 0 0 auto; /* Prevents the footer from growing or shrinking */
}
